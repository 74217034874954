<template>
<span>

    <v-toolbar flat color="white">
        <v-toolbar-title >
            <img v-if="product.prod_foto_destaque" :src="`${base_url}upload/product_photo/q/${product.prod_foto_destaque}`" height="50">
            <img v-if="!product.prod_foto_destaque" :src="`${base_url}upload/logo.png`" height="50">
        </v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
        <h2 class="lime--text lighten-1">{{ product.prod_nome }}</h2>
        <v-spacer></v-spacer>
        <template>
            <v-btn color="blue-grey" dark class="mb-2" :to="{name: 'products'}">
                <v-icon dark>keyboard_arrow_left</v-icon> Voltar
            </v-btn>
        </template>
    </v-toolbar>

    <table id="table-detalhe" class="table table-bordered table-condensed">
        <tbody>
            <tr>
                <th class="texto" width="10%">Nome:</th>
                <td width="40%" colspan="2">{{ product.prod_nome }}</td>
                <th class="texto" width="10%">Variação:</th>
                <td width="40%" colspan="2">{{ product.prod_variacao }}</td>
            </tr>
            <tr>
                <th class="texto" width="10%">Código Produto:</th>
                <td width="40%" colspan="2">{{ product.prod_codigo }}</td>
                <th class="texto" width="10%">SKU:</th>
                <td width="40%" colspan="2">{{ product.prod_sku }}</td>
            </tr>
            <tr>
                <th class="texto" width="10%">Código Item:</th>
                <td width="40%" colspan="2">{{ product.prod_cod_item }}</td>
                <th class="texto" width="10%">Referência:</th>
                <td width="40%" colspan="2">{{ product.prod_referencia }}</td>
            </tr>
            <tr>
                <th class="texto" width="10%">Valor:</th>
                <td width="40%" colspan="2">{{ product.prod_valor_b2c }}</td>
                <th class="texto" width="10%">Promoção:</th>
                <td width="40%" colspan="2">{{ product.prod_valor_promocao }}</td>
            </tr>
            <tr>
                <th class="texto" width="10%">Desconto:</th>
                <td width="40%" colspan="2">{{ product.prod_perc_desconto }}</td>
                <th class="texto" width="10%">Estoque:</th>
                <td width="40%" colspan="2">{{ product.prod_estoque }}</td>
            </tr>
            <tr>
                <th class="texto" width="10%">Tamanho:</th>
                <td width="40%" colspan="2">{{ product.prod_tamanho }}</td>
                <th class="texto" width="10%">Potência:</th>
                <td width="40%" colspan="2">{{ product.prod_potencia }}</td>
            </tr>
            <tr>
                <th class="texto" width="10%">Peso:</th>
                <td width="40%" colspan="2">{{ product.prod_peso }}</td>
                <th class="texto" width="10%">Altura:</th>
                <td width="40%" colspan="2">{{ product.prod_altura }}</td>
            </tr>
            <tr>
                <th class="texto" width="10%">Largura:</th>
                <td width="40%" colspan="2">{{ product.prod_largura }}</td>
                <th class="texto" width="10%">Comprimento:</th>
                <td width="40%" colspan="2">{{ product.prod_comprimento }}</td>
            </tr>
            <tr>
                <th class="texto" width="10%">Volume:</th>
                <td width="40%" colspan="2">{{ product.prod_volume }}</td>
                <th class="texto" width="10%">Mostrar no Site:</th>
                <td width="40%" colspan="2">{{ (product.prod_show_site == '1') ? 'SIM' : 'NÃO' }}</td>
            </tr>
            <tr>
                <th class="texto" width="10%">Mostrar Preço:</th>
                <td width="40%" colspan="2">{{ (product.prod_show_preco == '1') ? 'SIM' : 'NÃO' }}</td>
                <th class="texto" width="10%">Destaque Home:</th>
                <td width="40%" colspan="2">{{ (product.prod_destaque_home == '1') ? 'SIM' : 'NÃO'}}</td>
            </tr>
            <tr>
                <th class="texto" width="10%">Em Promoção:</th>
                <td width="40%" colspan="2">{{ (product.prod_em_promocao == '1') ? 'SIM' : 'NÃO' }}</td>
                <th class="texto" width="10%">Produto Novo:</th>
                <td width="40%" colspan="2">{{ (product.prod_novo == '1') ? 'SIM' : 'NÃO'}}</td>
            </tr>
            <tr>
                <th class="texto" width="10%">Mais Vendido:</th>
                <td width="90%" colspan="2">{{ (product.prod_mais_vendido == '1') ? 'SIM' : 'NÃO' }}</td>
                <th class="texto" width="10%">Código Vídeo:</th>
                <td width="40%" colspan="2">{{ product.prod_videos }}</td>
            </tr>

            <tr>
                <th class="texto" width="10%">Breve Descrição:</th>
                <td width="90%" colspan="4">{{ product.prod_breve_descricao }}</td>
            </tr>
            <tr>
                <th width="10%">Palavras Chaves:</th>
                <td colspan="2">{{ product.prod_keywords }}</td>
                <th width="10%">Meta Descrição</th>
                <td colspan="2">{{ product.prod_meta_descricao }}</td>
            </tr>
            <tr>
                <th class="texto" width="10%" text>Tags:</th>
                <td width="90%" colspan="4" v-html="product.prod_tag"></td>
            </tr>
            <tr>
                <th class="texto" width="10%" text>Descrição:</th>
                <td width="90%" colspan="4" v-html="product.prod_desc"></td>
            </tr>
        </tbody>
    </table>

</span>
</template>

<script>
import {
    URL_BASE
} from '../../../config/configs'

export default {
    name: "DetailProductComponent",
    created() {
        this.loadProduct()
    },
    props: {
        prod_id: {
            require: true
        }
    },
    data() {
        return {
            product: {},
            base_url: URL_BASE
        }
    },
    methods: {
        loadProduct() {
            this.$store.dispatch('loadProduct', this.prod_id)
                .then(response => {
                    this.product = response
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: 'Erro',
                        text: 'Dados não localizado',
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
        },
    }
};
</script>

<style scoped>
.avatar {
    height: 25px;
}

.logo {
    height: 50px;
}

.table th.texto {
    vertical-align: top;
}
</style>
